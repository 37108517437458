<!-- 对账单下载 列表页面 -->
<template>
    <div class="mt-4 mx-4">
        <div style="margin: 10px 10px 10px 10px;">
        <ath-breadcrumbs :items="navigationItems" flat="false" txtColor="" bgColor="" />
        <v-row>
            <v-col>
                <ath-date-picker :label="this.$t('bill.startDate')" v-model="startDate" :minMonth="minMonth"  />
            </v-col>
            <v-col>
                <ath-date-picker :label="this.$t('bill.endDate')" v-model="endDate" :minMonth="minMonth"  />
            </v-col>
            <v-col>
                <v-autocomplete v-model="statementType" :label="this.$t('bill.statementType')" :items="statementTypes" :item-text="'label'" :item-value="'value'"
                />
            </v-col>
            <v-col>
                <v-autocomplete v-model="dateType" :label="this.$t('bill.dateType')" :items="dateTypes" :item-text="'label'" :item-value="'value'"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-autocomplete v-model="merId" :label="this.$t('bill.merName')" :items="merchantItems" :item-text="'label'" :multiple=true :item-value="'value'"
                />
            </v-col>
        </v-row>
    </div>
    <div style="margin: 10px 10px 10px 10px;">
        <v-row >
            <v-col>
                <ath-button
                    class="primary"
                    icon="mdi-reload"
                    :text="this.$t('common.operate.reset')"
                    @click="resetBtn"/>
            </v-col>
            <v-col>
                <ath-button
                    class="primary"
                    icon="mdi-magnify"
                    :text="this.$t('label.search')"
                    @click="fetchDatas"/>
            </v-col>
            <v-col>
                <ath-button
                    class="primary"
                    icon="mdi-arrow-down-thick"
                    :text="this.$t('action.downloads')"
                    @click="downloadBtn"/>
            </v-col>
            <v-col></v-col><v-col></v-col><v-col></v-col><v-col></v-col><v-col></v-col><v-col></v-col><v-col></v-col>
            <v-col></v-col><v-col></v-col><v-col></v-col><v-col></v-col><v-col></v-col><v-col></v-col>
        </v-row>
    </div>
    <div style="margin: 10px 10px 10px 10px;">
        <ath-data-table
            :headers="getHeaders"
            :items="tbl.items"
            :total="tbl.total"
            :record-actions="recordActions"
            selectable="true"
            item-key="id"
            v-model="delRerocds"
            @action:row="onActionRow"
            @update:options="onUpdateOptions"
        >
        <template v-slot:item.actions="{item}">
            <ath-button class="primary" size="small" icon="mdi-arrow-down-thick" text="Download" @click="downloadBill(item)" />
        </template>
    </ath-data-table>
     </div>
</div>
</template>

<script>
    export default {
        name: 'BillDownload',
        props: {},
        data() {
            return {
                navigationItems: [
                    { text: this.$t('menu.billDownload'), disabled: true, to: '' }
                ],
                delRerocds:[],
                options:{},
                startDate: this.setDate(),
                endDate: this.setDate(),
                statementTypes:[],
                merId: "",
                merchantItems: [],
                statementType: "",
                dateType: "d",
                dateTypes: [

                ],
                tbl: {
                    loading: true,
                    items: [],
                    total: 0
                },
            };
        },
        watch: {
            '$athlon.i18n.locale': {
                handler(newValue) {
                    this.navigationItems = [
                        { text: this.$t('menu.billDownload'), disabled: true, to: '' }
                    ];
                    this.initData();
                },
                deep: true
            }
        },
        computed: {
            getHeaders(){
            let headers = [
                { text: this.$t("bill.startDate"), value: 'startDate', sortable:false},
                { text: this.$t('bill.merName'), value: 'merName', sortable:false},
                { text: this.$t("bill.statementType"), value: 'statementName', sortable:false},
                { text: this.$t("bill.dateType"), value: 'dateTypeName', sortable:false},
                { text: this.$t('action.download'), value: 'actions', sortable:false, width: '12%' }
            ];
            return headers;
        },
        recordActions(){
            let edit = [
                { name: 'download', icon: 'mdi-arrow-down-thick', text: this.$t('action.download') },
            ]
            return edit;
        }
        },
        created() {
            this.initData();
        },
        methods: {
            onUpdateOptions(options) { //后端分页，监听翻页事件
                this.delRerocds = [];
                this.options = options;
                if(this.tbl.items.length > 0){
                    this.fetchData();
                    return;
                }
            },
            initData() {
                this.$loading(this.$t('msg.loading'));
                let langcode = this.$athlon.i18n.locale;
                langcode = langcode.replace(/-/g, '_');
                let param = {
                    lang: langcode
                }
                this.statementTypes = [];
                this.dateTypes = [];
                this.merchantItems =[];
                this.$athlon.request.post('bill/getDict',param, this.$athlon.config.api.requestParamheaders).then((res) => {
                    if (res.data.allMers != null) {
                        this.merchantItems.push({
                            value: '',
                            label: this.$t('common.operate.all')
                        });
                        this.statementTypes.push({
                            value: '',
                            label: this.$t('common.operate.all')
                        });
                        this.dateTypes.push({
                            value: '',
                            label: this.$t('common.operate.all')
                        });
                        for (let i = 0; i < res.data.allMers.length; i++) {
                            this.merchantItems.push({
                                value: res.data.allMers[i].merId,
                                label: '['+res.data.allMers[i].merId+']'+res.data.allMers[i].merShortName
                            });
                        }
                        for(let key in res.data.statementList ){
                            this.statementTypes.push({ label: res.data.statementList[key], value:key});
                        }
                        for(let key in res.data.dateTypeList ){
                            this.dateTypes.push({ label: res.data.dateTypeList[key], value:key});
                        }
                        this.merId = this.merchantItems[0].value;
                        this.statementType = "";
                        this.dateType= "";
                    }
                    this.fetchData();
                }).catch((err) => {
                    this.$loading(false);
                    this.tipsMsg(this.$t('error.500') + `：${err.message}`);
                });
            },
            onActionRow(operateType, items){
                this.downloadBill(items);
            },
            downloadBill(items) {
                let param = {
                    id: items.id
                }
                this.$athlon.request.post('bill/fileIsExists', param, this.$athlon.config.api.requestParamheaders).then((res) => {
                    if (res.data.code == '9999') {
                        this.tipsMsg(this.$t('bill.fileIsNotExists'));
                    } else {
                        let params = new URLSearchParams();
                        params.append('id', items.id);
                        this.downloadByPost("bill/downloadBill", params);
                        this.$loading(false);
                    }
                }).catch((err) => {
                    this.$loading(false);
                    this.tipsMsg(this.$t('error.500')+`：${err.message}`);
                });
            },
            
            downloadBtn(){
                if(this.delRerocds.length == 0){
                    this.tipsMsg(this.$t('bill.msg.download_select'));
                }else{
                    this.$dialog.open({
                        title: this.$t('athlon.common.hint'),
                        component: { template: '<lable class="ma-10">'+this.$t('bill.msg.confirmDelte')+'</lable>' },
                        
                        footBtn: [
                            {eventId: 11, color: 'primary', text: this.$t('action.confirm'),icon:'mdi-check'},
                            {eventId: 12, color: 'default', text: this.$t('action.cancel'),icon:'mdi-close'},
                        ],
                        onaction: (e, data) => {
                            if(e.eventId == 11){
                                let ids = "";
                                for(let i = 0; i < this.delRerocds.length; i++){
                                    if(i==this.delRerocds.length-1){
                                        ids += this.delRerocds[i].id
                                    }else{
                                        ids += this.delRerocds[i].id+","
                                    }
                                }
                                let params = new URLSearchParams();
                                params.append('ids', ids);
                                this.downloadByPost("bill/batchDownloadBill", params);
                                this.$dialog.close();
                            }else if(e.eventId == 12) {
                                this.$dialog.close();
                            }
                        }
                    })
                }
            },
            setDate() {
                let date = new Date();
                date.setDate(date.getDate()-1);
                let year = date.getFullYear()+'';
                let month = date.getMonth()+1>9?(date.getMonth()+1)+'':'0'+(date.getMonth()+1);
                let day = date.getDate()>9?date.getDate()+'':'0'+date.getDate();
                return year +'-'+month+'-'+day
            },

            resetBtn() {
                this.startDate = this.setDate(),
                this.endDate = this.setDate(),
                this.merId = '';
                this.statementType = '';
                this.dateType= "";
                this.options.page = 1;
                this.options.itemsPerPage =10;
                this.totalCount = 0;
                this.fetchData();
            },

            fetchDatas(){
                this.options.page = 1;
                this.options.itemsPerPage =10;
                this.totalCount = 0;
                this.fetchData();
            },

            fetchData(){
                this.delRerocds = [];
                this.$loading(this.$t('msg.loading'));
                this.tbl.items = [];
                let limit = this.options.itemsPerPage || 10;
                let offset = (this.options.page - 1)*this.options.itemsPerPage || 0;
                if(limit = -1){
                    limit = 1000;
                }
                let param = {
                    merId: this.merId,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    statementType: this.statementType,
                    dateType: this.dateType,
                    startRowId: offset,
                    endRowId:  limit
                }
                this.$athlon.request.post('bill/queryList', param, this.$athlon.config.api.requestParamheaders).then((res) => {
                    if (res.data.code == '0') {
                        this.tbl.total = res.data.totalCount || 0;
                        this.tbl.items = [];
                        let detils = res.data.data || [];
                        for (let i = 0; i < detils.length; i++) {
                            let merName = "";
                            let statementName = "";
                            let dateTypeName = "";
                            for (let j = 0; j < this.merchantItems.length; j++) {
                                if(detils[i].merId === this.merchantItems[j].value){
                                    merName = this.merchantItems[j].label;
                                }
                            }
                            for (let k = 0; k < this.statementTypes.length; k++) {
                                if(detils[i].statementType === this.statementTypes[k].value){
                                    statementName = this.statementTypes[k].label;
                                }
                            }
                            for (let l = 0; l < this.dateTypes.length; l++) {
                                if(detils[i].dateType === this.dateTypes[l].value){
                                    dateTypeName = this.dateTypes[l].label;
                                }
                            }
                            this.tbl.items.push({
                                id:detils[i].id,
                                startDate: detils[i].startDate,
                                endDate: detils[i].endDate,
                                statementType: detils[i].statementType,
                                dateType: detils[i].dateType,
                                merId: detils[i].merId,
                                merName: merName,
                                statementName: statementName,
                                dateTypeName: dateTypeName,
                                DataTableRecordActions: ['download']
                            });
                        }
                        this.totalCount = res.data.totalCount || 0;
                    }
                    if(offset >= this.totalCount){
                        this.options.page = 1;
                    }
                    this.$loading(false);
                }).catch((err) => {
                    this.$loading(false);
                    this.tipsMsg(this.$t('error.500')+`：${err.message}`);
                });
            }
        },
    };
</script>
